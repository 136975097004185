$(document).ready(function () {

    let onMouseEnter = function (event) {
        let winWidth = $(window).width();
        if(winWidth >= 1200) {
            let id = $(event.target).parents(".row").first().attr("id");
            $("#" + id).find(".js-visualBoxLink").first().toggleClass("visualBox__action-link-extended");
        }
    };

    let onMouseLeave = function (event) {
        let winWidth = $(window).width();
        if(winWidth >= 1200) {
            let id = $(event.target).parents(".row").first().attr("id");
            $("#" + id).find(".js-visualBoxLink").first().toggleClass("visualBox__action-link-extended");
        }
    }

    $(".js-visualBoxLink").hover(function(event) {
        onMouseEnter(event);
    }, function(event) {
        onMouseLeave(event);
    });

    $(".js-visualBoxImage").on("click", function(event) {
        $(event.target).parent().next().find("span").click();
    })

    var visualBoxSliderOptions = {
        "default" : {
            arrows: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            responsive: [
                {
                    breakpoint:  1200,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        }
    }

    $(".js-visualBoxSlider").each(function(index) {
        let imageNumber = $(this).find('.slide').length;
        if (imageNumber > 1) {

            // dynamic styling of the scroll marker and scroll text elements
            let cousinColor = $(this).parent().siblings().children().css('color');
            $(this).siblings('.visualBoxSlider__scrollMarker').css('background-color', cousinColor);
            $(this).siblings('.visualBoxSlider__scrollText').css('color', cousinColor);

            if($(this).parent().hasClass('visualBox__gallery--right')) {
                $(this).siblings('.visualBoxSlider__scrollMarker').addClass('visualBoxSlider__scrollMarker--right')
                $(this).siblings('.visualBoxSlider__scrollText').addClass('visualBoxSlider__scrollText--right')
            } else {
                $(this).siblings('.visualBoxSlider__scrollMarker').addClass('visualBoxSlider__scrollMarker--left')
                $(this).siblings('.visualBoxSlider__scrollText').addClass('visualBoxSlider__scrollText--left')
            }

            /* TODO: auxiliary code for the scrollMarker animation routine: uncomment (and UPDATE) if the feature is approved, cancel otherwise
            $(this).siblings('.visualBoxSlider__scrollMarker').css('width', 100/imageNumber.toString() +'%');
            */

            var sliderType = $(this).attr('data-slider');

            if (!sliderType) {
                sliderType = "default";
            }

            $(this).slick(visualBoxSliderOptions[sliderType]);
            handlePlyrInsideSlider($(this));
        }
    });

    /* TODO: scrollMarker animation manager: uncomment (and UPDATE) if the feature is approved, cancel otherwise.
    $(".js-visualBoxSlider").on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        let leftStep = 100/slick.slideCount;
        $(this).siblings('.visualBoxSlider__scrollMarker').css('left', (leftStep * nextSlide).toString() + '%');
    });*/

    let visualBoxGallerySliderOptions = {
        "default" : {
            arrows: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            autoplay: true,
            responsive: [
                {
                    breakpoint:  1200,
                    settings: {
                    }
                },
                {
                    breakpoint:  768,
                    settings: {
                    }
                }
            ]
        }
    }

    $(".js-visualBoxGallerySlider").each(function() {

        if ($(this).find('.slide').length > 1) {

            let sliderType = $(this).attr('data-slider');

            if (!sliderType) sliderType = "default";

            $(this).slick(visualBoxGallerySliderOptions[sliderType]);
            handlePlyrInsideSlider($(this));
        }
    })

});
